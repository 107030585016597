import React from "react";
import { useRecoilState } from "recoil";
import { simuParameters, globalSimuResults } from "../App";
import { useRef, useEffect } from "react";
import { params } from "./svgs";
import MathJax from "react-mathjax";
import { Pie, Bar } from "react-chartjs-2";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import 'chart.js/auto';

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function getNameAndUnits(shortName) {
  const param = params.find((param) => param.shortName === shortName);
  return param ? param.fullName + " (" + param.unit + ")" : null;
}

function downloadPlots(plotName) {
  const svgElement = document.getElementById(plotName); // Select your SVG element here
  const svgContent = new XMLSerializer().serializeToString(svgElement);
  // Create a Blob and create a download link
  const blob = new Blob([svgContent], { type: "image/svg+xml" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = plotName + ".svg";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function adjustSvgSize(svgString) {
  // Parse the SVG string into a DOM Document
  const parser = new DOMParser();
  const doc = parser.parseFromString(svgString, 'image/svg+xml');
  const svgElement = doc.documentElement;

  // Remove existing width and height attributes
  svgElement.removeAttribute('width');
  svgElement.removeAttribute('height');

  // Set width and height to 100%
  svgElement.setAttribute('width', '800');
  svgElement.setAttribute('height', '800');

  // Ensure viewBox is set
  if (!svgElement.hasAttribute('viewBox')) {
    // Use default dimensions if width and height are not specified
    const originalWidth = svgElement.getAttribute('width') || '100';
    const originalHeight = svgElement.getAttribute('height') || '100';
    svgElement.setAttribute('viewBox', `0 0 ${originalWidth} ${originalHeight}`);
  }

  // Set preserveAspectRatio to maintain the original aspect ratio
  svgElement.setAttribute('preserveAspectRatio', 'xMidYMid meet');

  // Serialize the SVG back to a string
  const serializer = new XMLSerializer();
  const adjustedSvgString = serializer.serializeToString(svgElement);

  return adjustedSvgString;
}

function Results() {
  const [simResults] = useRecoilState(globalSimuResults);
  const [simuParams] = useRecoilState(simuParameters);

  if (!simResults) {
    return <p>No results to display.</p>;
  }

  const { img, svg, stats_after, mesh_config } = simResults;

  // Adjust the SVG size
  const adjustedSvg = svg ? adjustSvgSize(svg) : null;

  const customKeys = {
    stats_after: {
      total_area: "Total open area",
      max_area: "Biggest area",
      min_area: "Smallest area",
      mean_area: "Mean area size",
      std_area: "Standard deviation (Open Areas)",
      median_area: "Median area size",
      percentile_5: <>Areas<sub>5%</sub></>,
      percentile_25: <>Areas<sub>25%</sub></>,
      percentile_75: <>Areas<sub>75%</sub></>,
      percentile_95: <>Areas<sub>95%</sub></>,
      len_areas: "Number of open areas",
    },
    mesh_config: {
      angle_degrees: "Mesh Angle",
      layout_shift_x: "Layout Shift (X)",
      layout_shift_y: "Layout Shift (Y)",
      mesh_count_per_inch_x: "Meshcount (Weft)",
      mesh_count_per_inch_y: "Meshcount (Warp)",
      wire_diameter_x: "Wire diameter (Weft)",
      wire_diameter_y: "Wire diameter (Warp)",
    },
  };

  const units = {
    angle_degrees: " °",
    layout_shift_x: " µm",
    layout_shift_y: " µm",
    mesh_count_per_inch_x: <>1&#x2044;inch</>,
    mesh_count_per_inch_y: <>1&#x2044;inch</>,
    wire_diameter_x: " µm",
    wire_diameter_y: " µm",
    total_area: " µm²",
    max_area: " µm²",
    min_area: " µm²",
    mean_area: " µm²",
    std_area: " µm²",
    median_area: " µm²",
    percentile_5: " µm²",
    percentile_25: " µm²",
    percentile_75: " µm²",
    percentile_95: " µm²",
    len_areas: "",
  };

  const noDecimalKeys = [
    "len_areas",
    "mesh_count_per_inch_x",
    "mesh_count_per_inch_y",
    "wire_diameter_x",
    "wire_diameter_y",
  ];

  const pieData = {
    labels: ["Over critical size", "Under critical size"],
    datasets: [
      {
        data: [stats_after.mean_area, stats_after.max_area],
        backgroundColor: ["#C92F50FF", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#EBCE85FF"],
      },
    ],
  };

  const pieOptions = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.label || "";
            const value = tooltipItem.raw || 0;
            return `${label}: ${Number(value).toFixed(1)} µm²`;
          },
        },
      },
    },
  };

  const barData = {
    labels: ["Biggest Area", "Mean Area", "Smallest Area", "Median Area"],
    datasets: [
      {
        label: "Area sizes",
        data: [
          stats_after.max_area,
          stats_after.mean_area,
          stats_after.min_area,
          stats_after.median_area,
        ],
        backgroundColor: ["#C92F50FF", "#36A2EB", "#FFCE56", "#1CC4C4FF"],
        borderColor: ["#FF6384", "#6DB7E9FF", "#EBCE85FF", "#6BE0E0FF"],
        borderWidth: 1,
      },
    ],
  };

  const barOptions = {
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: false,
          text: "Area [µm²]",
        },
      },
      x: {
        title: {
          display: false,
          text: "Data Points",
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.dataset.label || "";
            const value = tooltipItem.raw || 0;
            return `${Number(value).toFixed(1)} µm²`;
          },
        },
      },
    },
  };

  return (
    <MathJax.Provider>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ marginRight: "20px" }}>
          {img ? (
            // Render the image if it exists
            <TransformWrapper>
              <TransformComponent>
                <img
                  src={`data:image/png;base64,${img}`}
                  alt="Simulation result"
                  style={{ maxWidth: "600px", maxHeight: "600px" }}
                />
              </TransformComponent>
            </TransformWrapper>
          ) : adjustedSvg ? (
            // Render the adjusted SVG
            <div
              style={{
                width: "800px",
                height: "800px",
                overflow: "hidden",
              }}
            >
              <TransformWrapper>
                <TransformComponent>
                  <div
                    dangerouslySetInnerHTML={{ __html: adjustedSvg }}
                    style={{ width: "100%", height: "100%" }}
                  />
                </TransformComponent>
              </TransformWrapper>
            </div>
          ) : (
            <p>No visual results available.</p>
          )}
        </div>

        <div>
          <h3 style={{ fontWeight: "bold", textDecoration: "underline" }}>
            Open Area Information:
          </h3>
          <ul>
            {Object.entries(stats_after).map(([key, value]) => (
              <li key={key}>
                <strong>{customKeys.stats_after[key] || key.replace("_", " ")}:</strong>{" "}
                {noDecimalKeys.includes(key)
                  ? Number(value).toFixed(0)
                  : Number(value).toFixed(1)}{" "}
                {units[key] || ""}
              </li>
            ))}
          </ul>
          {/* Empty line */}
          <div style={{ marginBottom: "20px" }}></div>
          <h3 style={{ fontWeight: "bold", textDecoration: "underline" }}>
            Optimized Mesh Parameters:
          </h3>
          <ul>
            {Object.entries(mesh_config).map(([key, value]) => (
              <li key={key}>
                <strong>{customKeys.mesh_config[key] || key.replace("_", " ")}:</strong>{" "}
                {noDecimalKeys.includes(key)
                  ? Number(value).toFixed(0)
                  : Number(value).toFixed(1)}{" "}
                {units[key] || ""}
              </li>
            ))}
          </ul>
        </div>

        <div style={{ marginLeft: "20px" }}>
          <h3 style={{ fontWeight: "bold", textDecoration: "underline" }}>
            Critical Size (Not Implemented Yet)
          </h3>
          <Pie data={pieData} options={pieOptions} />
          <h3
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
              marginTop: "20px",
            }}
          >
            Open Area Distribution:
          </h3>
          <Bar data={barData} options={barOptions} />
        </div>
      </div>
    </MathJax.Provider>
  );
}

export default Results;
