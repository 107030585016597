import React from "react";
import * as d3 from "d3";
import { useRef, useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { useRecoilState } from "recoil";
import { meshState, simuParameters } from "../App";
import { Loader } from "./svgs";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function Heatmap() {
  const parent = useRef();
  const svgRef = useRef();
  const [loading, setloading] = useState(true);
  const [plotData, setplotData] = useState(null);
  const [simuParams, setsimuParams] = useRecoilState(simuParameters);
  const [dims] = useWindowSize();

  const margin = { top: 0, right: 45, bottom: 45, left: 200 };
  // const width = parent.current?.offsetWidth - margin.left - margin.right;
  // const height = parent.current?.offsetHeight - margin.top - margin.bottom;
  const width = 870 - margin.left - margin.right;
  const height = 670 - margin.top - margin.bottom;

  const drawPlot = async () => {
    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    svg.selectAll("*").remove();

    const top = svg
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    const xScale = d3
      .scaleLinear()
      .range([0, width])
      .domain([plotData.xDomain[0], plotData.xDomain[1]]);
    const yScale = d3
      .scaleLinear()
      .range([0, height])
      .domain([plotData.yDomain[1], plotData.yDomain[0]]);

    top
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", width)
      .attr("height", height)
      .attr("fill", "#fff")
      .attr("stroke", "#000")
      .attr("clip-path", "url(#clip)");

    top
      .append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", width)
      .attr("height", height)
      .attr("rx", 30)  // Abgerundete Ecken horizontal
      .attr("ry", 30); // Abgerundete Ecken vertikal

    const tooltip = d3.select("#hoverInfo");

    const polygons = top
      .selectAll("polygons")
      .data(plotData.polygons)
      .enter()
      .append("g");

    const f = d3.format(".1f");

    polygons
      .append("polygon")
      .attr("clip-path", "url(#clip)")
      .attr("fill", "#4f46e5")
      .attr("stroke", "#000000")
      .attr("points", (d) => {
        let str = "";
        d.vertices.map((xy, i) => {
          str = str + xScale(xy[0]) + "," + yScale(xy[1]) + " ";
        });
        return str;
      })
      .on("mouseover", (event, d) => {
        tooltip.style("visibility", "visible");
        tooltip.text(f(d.area) + " µm²");
        d3.select(event.target).attr("fill", "#3730a3");
      })
      .on("mouseout", (event) => {
        tooltip.style("visibility", "hidden");
        d3.select(event.target).attr("fill", "#4f46e5");
      })
      .on("mousemove", function (event) {
        return tooltip
          .style("top", d3.pointer(event)[1] + "px")
          .style("left", d3.pointer(event)[0] + "px");
      });

    //const xAxis = d3.axisBottom(xScale).ticks(5).tickFormat(d3.format("d"));
    //top
      //.append("g")
      //.style("font", "14px poppins")
      //.call(xAxis)
      //.attr("transform", `translate(0, ${height})`);

    //const yAxis = d3.axisLeft(yScale).ticks(5).tickFormat(d3.format("d"));
    //top.append("g").style("font", "14px poppins").call(yAxis);

  };

  const params = new URLSearchParams([
    ["mcx", simuParams.mesh.mcx],
    ["mcy", simuParams.mesh.mcy],
    ["dx", simuParams.mesh.dx],
    ["dy", simuParams.mesh.dy],
    ["kx", simuParams.mesh.kx],
    ["ky", simuParams.mesh.ky],
    ["theta", simuParams.mesh.theta],
    ["phi", simuParams.mesh.phi],
    ["xsize", simuParams.mesh.size],
  ]);
  const url = "/api/single_mesh/";

  const fetchPlotData = async () => {
    setloading(true);
    const response = await axios.get(url, {
      params,
    });
    console.log("new", response.data);
    setplotData(response.data);
    setloading(false);
  };

  useEffect(() => {
    console.log(simuParams);
    fetchPlotData();
  }, [simuParams.mesh]);

  useEffect(() => {
    if (plotData) {
      setloading(true);
      drawPlot();
      setloading(false);
    }
  }, [plotData, dims]);

  return (
    <div ref={parent} className="w-full aspect-video relative">
      <svg ref={svgRef}></svg>
      <div
        id="hoverInfo"
        className="absolute bg-white px-2 py-1 rounded-md shadow-black shadow-md invisible"
      >
        Hello
      </div>
      {loading ? (
        <div className="absolute inset-0 w-16 aspect-square m-auto my-auto">
          {Loader}
        </div>
      ) : null}
    </div>
  );
}

export default Heatmap;
