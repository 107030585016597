import React from "react";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { simuParameters } from "../App";
import { useRecoilState } from "recoil";
import { fraunhofer_gray } from "./svgs";

function Handout() {
  const [UserContact, setUserContact] = useState("");
  const [simuParams] = useRecoilState(simuParameters);

  const postForCallback = async () => {
    const url = "/api/post_user_content/";

    const formData = new FormData();
    if (UserContact) {
      formData.append("contact", UserContact);
    } else {
      formData.append("contact", "NAN");
    }
    formData.append("mask", simuParams.mask);
    formData.append("mesh", JSON.stringify(simuParams.mesh));
    formData.append("sweepInfo", JSON.stringify(simuParams.sweepParams));
    const response = await axios.post(url, formData).catch(() => {});
    setUserContact("");
  };

  return (
    <div className="grid grid-cols-2 mb-16">
      <div className="flex flex-col">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
          Your Contact
        </label>
        <input
          className="appearance-none block w-full bg-white text-gray-700 rounded-lg py-2 px-2 border-2 leading-tight focus:outline-none focus:border-[#005B7F]"
          type="text"
          onChange={(e) => setUserContact(e.target.value)}
          placeholder="E-Mail, Name, etc."
          value={UserContact}
        />
        <button
          //className="transition mx-auto mt-8 ease-in-out hover:scale-105 hover:translate-y-1 bg-[#005B7F] bg-gradient-to-bl from-[#005B7F] to-fuchsia-600 cursor-pointer hover:bg-gray-200 text-gray-200 font-semibold  py-2 px-8 shadow-lg border-[#005B7F] hover:border-[#005B7F] hover:border-transparent rounded"
          className="mt-8 transition ease-in-out hover:scale-105 hover:translate-y-1 mx-auto my-8 bg-[#179C7D] bg-gradient-to-bl from-[#179C7D] to-#008598 cursor-pointer hover:bg-gray-200 text-gray-200 font-semibold  py-2 px-8 shadow-lg border-indigo-600 hover:border-[#008598] hover:border-transparent rounded-xl"
          onClick={postForCallback}
        >
          Stay in Touch!
        </button>
      </div>
      <div className="hf justify-self-end">{fraunhofer_gray}</div>
    </div>
  );
}

export default Handout;
